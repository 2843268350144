import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useProgramUsersContractsList() {
  // Use toast
  const toast = useToast()

  const refPaymentContractsListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    { key: 'user_name', label: 'Applicant Name', sortable: true },
    { key: 'pc_name', label: 'PC Name', sortable: true },
    { key: 'selected_option_name', label: 'PC Option Name', sortable: true },
    { key: 'pay_condition_start_date', label: 'Start Date', sortable: false },
    { key: 'count_of_payments', label: 'Number of Payments', sortable: false },
    { key: 'payment_method', label: 'Payment Method', sortable: false },
    { key: 'status', label: 'Status', sortable: false },
  ]
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterDataStatus = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataStatus.value = ''
  }

  const refetchData = () => {
    refPaymentContractsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterDataStatus, programId], () => {
    refetchData()
  })

  const fetchPaymentContractsList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value ? sortBy.value : 'created_at',
      sortDesc: sortBy.value ? isSortDirDesc.value : true,
    }

    if (programId.value) {
      payloadData.programId = programId.value
    }
    if (searchQuery.value) {
      payloadData.userName = searchQuery.value
    }
    if (filterDataStatus.value) {
      payloadData.paymentContractStatus = filterDataStatus.value
    }

    store
      .dispatch('app-user-contracts/fetchApplicationPaymentContracts', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching reports list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const actionOptions = []

  const resolveStatusVariant = status => {
    if (status === 'Approved') return 'success'
    if (status === 'Unselected') return 'danger'
    if (status === 'Selected') return 'warning'
    return 'primary'
  }

  return {
    fetchPaymentContractsList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterDataStatus,
    sortBy,
    totalItems,
    isSortDirDesc,
    refPaymentContractsListTable,
    actionOptions,
    clearFilters,
    refetchData,
    resolveStatusVariant,
  }
}
